//

import CssBaseline from './CssBaseline';
import Card from './Card';
import Button from './Button';
import LinearProgress from './LinearProgress';
import Input from './Input';
import Table from './Table';
import Dialog from './Dialog';
import AutoComplete from './AutoComplete';
import Slider from './Slider';
import Checkbox from './Checkbox';
import Accordion from './Accordioan';
import Tabs from './Tabs';
import Switch from './Switch';
import Divider from './Divider';


// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Accordion(theme),
    CssBaseline(theme),
    Card(theme),
    Button(theme),
    Divider(theme),
    LinearProgress(theme),
    Input(theme),
    Checkbox(theme),
    Table(theme),
    Dialog(theme),
    AutoComplete(theme),
    Slider(theme),
    Switch(theme),
    Tabs(theme)
  );
}
