import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionContext } from "../contexts/actionContext";
import CustomIcon from "../mini-components/CustomIcon";
import Timer from "../mini-components/Timer";
import { GameDataContext } from "../services/GameCommunication";

export default function Header(){

    const {setOpenSideDrawer} = useContext(ActionContext);
    const {roundId} = useContext(GameDataContext);

    return(
        <Box
            sx={{
                position:"fixed",
                top:0,
                left:0,
                width:"100vw",
                zIndex:10,
                // background:"linear-gradient(180deg, rgba(0, 0, 0, 0.4) 2.93%, rgba(0, 0, 0, 0) 95.79%)",
                px:"10px",
                py:"10px",
            }}
        >
            {/* <Box
                sx={{
                    px:3,
                    py:1,
                    borderRadius:"50px",
                    width:"fit-content",
                    position:"relative",
                    backdropFilter:"blur(5px)",
                }}
            >
                
                <Stack 
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"15px"}
                    width={"fit-content"}
                >
                    <Box
                    onClick={()=>setOpenSideDrawer(true)}
                        sx={{
                            // position:"absolute",
                            // left:20,
                            // top:"50%",
                            // transform:"translateY(-50%)"
                        }}
                    >
                        <CustomIcon 
                            // onClick
                            sx={{
                                width:28,
                                height:23,
                            }}
                            icon={"menu"}  />
                    </Box>
                    <Stack direction={"column"} spacing={0.4} alignItems={"start"}>
                        <Typography variant="body3" sx={{fontWeight:600}} color={"text.secondary"}>Roulette</Typography>
                        <Typography variant="subtitle3" color={"text.secondary"} >RID: {roundId}</Typography>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        position:"absolute",
                        width:"100%",
                        height:"100%",
                        borderRadius:"50px",
                        bgcolor:"rgba(0, 0, 0, 0.5)",
                        // backdropFilter:"blur(5px)",
                        backgroundClip:"padding-box",
                        left:0,
                        top:0,
                        zIndex:-1
                    }}
                />
            </Box> */}
            <Box
                 position={"relative"}
                 top={-10}
                 left={-10}
                 width={"60%"}
                 maxWidth={"250px"}
            >
                <Box
                    component={"img"}
                    src={"uiAssets/images/header.svg"}
                    top={0}
                >
                </Box>
                <Typography variant="subtitle3" sx={{color:"#000",position:"absolute",left:"42%",top:"20%",fontStyle:"italic"}} >RID: {roundId}</Typography>
                <Typography variant="body3" sx={{fontWeight:600,position:"absolute",left:"25%",top:"43%",fontStyle:"italic"}} color={"text.secondary"}>Roulette</Typography>
                <Box
                onClick={()=>setOpenSideDrawer(true)}
                    sx={{
                        position:"absolute",
                        top:15,
                        left:10,
                        // bgcolor:"orange",
                        width:"10%",
                        height:"40%"
                    }}
                >

                </Box>
            </Box>
            <Timer />
        </Box>
    )
}