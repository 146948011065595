
import { Box, Stack, TextField, Button,Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';


export default function LoginPage({checkLoggedIn=()=>{}}){

    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");


    const loginUser = async() =>{
        try {
            if(username && password){
                const res = await axios.post("http://13.201.94.191:3000/api/user/login",{
                    "email":username,
                    "password":password
                },
            {
                headers:{
                    'Secret-key':'juAEmwzg66qamA712ZoDs2AS3dZZQiy-',
                    "Content-Type":"application/json"
                }
            })
            console.log(res);
            if(res && res.data?.gameToken){
                const token =  res.data?.gameToken
                sessionStorage.setItem("token",token)
                sessionStorage.setItem("packageId",'5loQdyLECAVAbE6j65Ok7')
                sessionStorage.setItem("gameId",'PXSXM7llgX1nvu0gxsY1M')
                sessionStorage.setItem("operatorBy",'v0bymVyw7I0XpEv80KI8x')
                window.location.reload();
            }
            // checkLoggedIn();
            }
            else{
                alert("please provide valid Email or Password")
            }
        } catch (error) {
            alert(error.response.data.status_message);
        }
    }

    return(
        <Box sx={{
            position:"fixed",
            width:"100%",
            height:"100%",
            bgcolor:"#333",
            zIndex:9999999
        }}>
        
                <Box sx={{
                    position:"absolute",
                    top:"50%",
                    left:"50%",
                    transform:"translate(-50%,-50%)",
                }}>
                    <Stack direction={"column"} sx={{
                        width:"calc(100vw - 32px)",
                        maxWidth:"380px"
                    }} spacing={2}>
                        <Typography variant='h3' fontFamily={'Montserrat'} >GAMESANCT</Typography>
                        <TextField value={username} onChange={(e)=>setUsername(e.target.value)} variant='outlined' label="Email"  /> 
                        <TextField value={password} onChange={(e)=>setPassword(e.target.value)} variant='outlined' label="Password" /> 
                        <Button onClick={loginUser} variant='contained' sx={{fontSize:{xs:16,fontWeight:700}}} size='large'>Login</Button>
                    </Stack>
                </Box>
        </Box>
    )
}