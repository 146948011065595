import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ActionContext } from "../contexts/actionContext";
import SplashBackground from "../mini-components/SplashBackground";
import SplashForeground from "../mini-components/SplashForeground";
import { GameDataContext } from "../services/GameCommunication";


export default function Splashscreen(){

    const {gameState} = useContext(GameDataContext);
    const [splashScreen,setSplashScreen] = useState(true);
    const [orientation,setOrientation] = useState();
    const [percentage,setPercentage] = useState(0);

    useEffect(()=>{
        checkOrientation();
        window.addEventListener("orientationchange",checkOrientation);
        // lottiefile.layers[1].t.d.k[0].s.t = "Your".toUpperCase()
    },[])

    useEffect(()=>{
        if(gameState == "CONNECTED"){
            setSplashScreen(false);
        }
    },[gameState])

    const checkOrientation = () =>{
        var isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        if(isIos){
            if(Math.abs(window.orientation) === 90){
                console.log("Device is in landscape mode");
                setOrientation(0);
            }
            else{
                console.log("Device is in portrait mode");
                setOrientation(1);
            }
        }
        else{
            if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                console.log("Device is in portrait mode");
                setOrientation(1);
            } else if (window.screen.orientation.type === "landscape-primary" || window.screen.orientation.type === "landscape-secondary") {
                console.log("Device is in landscape mode");
                setOrientation(0);
            }
        }
    }

    useEffect(()=>{
        setTimeout(()=>{
            setPercentage(Math.floor(Math.random() * (40 - 20) + 20));
            setTimeout(()=>{
                setPercentage(Math.floor(Math.random() * (80 - 50) + 50));
            },2000)
        },1000)
    },[])

    useEffect(()=>{
        if(splashScreen && percentage>=50 && percentage<=80){
            setTimeout(()=>{
                setPercentage(Math.floor(Math.random() * (99 - 89) + 89));
                setTimeout(()=>{
                    setPercentage(100);
                },700)
            },500)
        }
    },[percentage,splashScreen])

    return(
        <Box
            sx={{
                position:"fixed",
                width:"100%",
                height:"100%",
                bgcolor:"white",
                top:0,
                left:0,
                zIndex:99999,
                display:percentage<100?"block":"none",
            }}
        >

            <SplashBackground orientation={orientation} />
            <SplashForeground orientation={orientation} percentage={percentage} />
        </Box>
    )
}