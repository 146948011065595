// ----------------------------------------------------------------------

export default function Accordion(theme) {
    return {
        MuiAccordion : {
        styleOverrides: {
            root:{
              boxShadow:"none",
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded':{
                    margin:0,
                    // borderBottom: `1px solid ${theme.palette.divider}`,
                    borderBottom: `none`,
                    // borderRadius:0,
                }
            },
            // expanded:{
            //     margin:0
            // }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root:{
              minHeight:"auto",
              padding:0,
              '&.Mui-expanded':{
                // borderRadius:0,
                minHeight:"auto"
            },
          },
            content:{
              margin:"0",
              '&.Mui-expanded':{
                // borderRadius:0,
                margin:"0"
            }
            }   
        }
      },
      MuiAccordionDetails : {
        styleOverrides: {
            root:{
                padding: 0,
            }   
        }
      },
    };
  }