import { Box, Fade, Slide, Stack, Typography } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { GameDataContext } from "../services/GameCommunication";
import palette from "../theme/palette";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
    exiting: {
        opacity: 0,
        transform: "translateY(10px)",
        // zIndex: -1
      },
      exited: {
        opacity: 0,
        transform: "translateY(10px)",
        // zIndex: -1
      },
      entering: {
        opacity: 0,
        transform: "translateY(-10px)",
      },
      entered: {
        opacity: 1,
        transform: "translateY(0px)",
        transition: "all 1000ms ease-in-out"
      }
}))

export default function RecentNumbers(){
    const {config,gameHistory,winNumber,pauseTimer,gameState} = useContext(GameDataContext);
    const [recentNumbers,setRecentNumber] = useState([]);
    const containerRef = useRef();
    const itemRef = useRef([]);

    
    const classes = useStyles();
    useEffect(()=>{
        if(gameHistory?.numbers && winNumber == -1){
            let temp = [];
            gameHistory?.numbers.slice(0,7).map((e)=>{
                temp.push({
                    key:uuidv4(),
                    value:e
                })
            });
            console.log(temp,"recent NUmber")
            setRecentNumber(temp);
        }
    },[gameHistory?.numbers]);
    useEffect(()=>{
        if(winNumber!==null && winNumber>-1 && pauseTimer == 8){
            setRecentNumber((prev)=>[{key:uuidv4(),value:winNumber},...prev.slice(0,-1)])
        }
    },[winNumber,pauseTimer])

    const getPosition = () =>{
        let sx  = {
            top:"50%",
            right:10,
            transform:"translateY(-50%)",
            py:6,
            px:0.5,
            background:"linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 51.56%, rgba(0, 0, 0, 0) 100%)",
        }

        if(config.orientation === "L"){
            sx={
                left:"50%",
                top:10,
                transform:"translateX(-50%)",
                px:6,
                py:0.5,
                background:"linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 51.56%, rgba(0, 0, 0, 0) 100%)",
            }
        }

        return sx;
    }

    const colors = {
        "#1FB02A":"#189A18",
        "#FF2727":"#FF3A29",
        "#FFFFFF":"#000000"
    }

    return(
        <TransitionGroup>
            <Stack
                ref={containerRef}
                direction={config.orientation === "L"?"row":"column"}
                sx={{
                    position:"fixed",
                    zIndex:1,
                    ...getPosition(),
                }}
            >
                    {
                        // gameHistory?.numbers.slice(0,pauseTimer<8 && gameState ==="RESULT" && winNumber !== null?6:7)
                        recentNumbers.map((e,i)=>{
                            // if(i==0){
                            //     return(
                            //         <Slide
                            //             key={`${e}-${i}`}
                            //             direction="down"
                            //             timeout={{ enter: 1000, exit: 1000 }}
                            //         >
                            //             <Stack
                            //             justifyContent={"center"}
                            //             alignItems={"center"}
                            //             sx={{
                            //                 p:1,
                            //                 color:"#FFF",
                            //                 bgcolor:colors[palette.numbers[e]],
                            //                 width:30,
                            //                 height:30,
                            //             }}
                            //             >
                            //                 <Typography fontWeight={700} variant="body3">{e}</Typography>
                            //             </Stack>
                            //         </Slide>
                            //     );
                            // }
                            


                            return(
                                <CSSTransition
                                    key={e.key}
                                    in={true}
                                    timeout={1000}
                                    classNames={{
                                    enter: classes.entering,
                                    enterActive: classes.entered,
                                    exit: classes.exiting,
                                    exitActive: classes.exited
                                    }}
                                    nodeRef={itemRef.current[i]}
                                >
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        sx={{
                                            // color:`numbers.${e}`,
                                            // bgcolor:"transparent",
                                            color:i==0?"#FFF":`numbers.${e.value}`,
                                            bgcolor:i==0?colors[palette.numbers[e.value]]:"transparent",
                                            
                                        }}
                                    >
                                        <Box
                                            ref={itemRef.current[i]}
                                            sx={{
                                                p:1,
                                                transform: "translateY(0px)",
                                                transition: "all 1000ms ease-in-out",
                                                width:30,
                                                height:30,
                                            }}
                                        >
                                        <Typography fontWeight={700} variant="body3">{e.value}</Typography>
                                </Box>
                                    </Stack>
                                </CSSTransition>
                            )
                        })
                    }
            </Stack>
        </TransitionGroup>
    )
}
