// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize:14,
          padding:"12px 24px !important",
          // backgroundColor:"#FFF",
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled }
          },
          [theme.breakpoints.up('xs')]: {
            minWidth:"auto",
          },
          [theme.breakpoints.up('md')]: {
            fontSize:14,
            minWidth:290,
          },
        },
        input: {
          
          borderRadius:8,
          "-webkit-user-select": "text" /* Chrome, Opera, Safari */,
          "-moz-user-select": "text" /* Firefox 2+ */,
          "-ms-user-select": "text" /* IE 10+ */,
          "user-select": "text" /* Standard syntax */,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
            [theme.breakpoints.up('md')]: {
              fontSize:14,
            },
          },
          padding:"0px",
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16]
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius:8,
          boxShadow:"none",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor:"#E1E1E1",
              borderWidth: 1,
          },
          '&.Mui-focused': {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#8F8F8F",
                borderWidth: 1,
            }
          },
          '&:hover': {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#8F8F8F",
                borderWidth: 1,
            }
          },
          '&.Mui-disabled': {
            backgroundColor: "#F8F8F8"
          }
        },
        input:{
          padding:"4px"
        }
      }
    },
    MuiMenu:{
      styleOverrides: {
        paper:{
          marginTop:"4px",
          border: "1px solid #E1E1E1",
          boxShadow:"none !important",
          minWidth:"290px !important"
        }
      }
    },
    MuiMenuItem:{
      styleOverrides: {
        root:{
          fontSize:14,
          [theme.breakpoints.up('xs')]: {
            minHeight:"auto",
          },
          [theme.breakpoints.up('md')]: {
            minHeight:24,
          },
        }
      }
    }
  };
}
