import { Stack } from "@mui/material";
import React from "react";
import ListItem from "./ListItem";

export default function List({data=[],sx={},colGap=0}){
    return(
        <Stack flex={1} sx={{...sx}} direction={"column"} spacing={colGap}>
            {
                data.map((_listitem)=>{
                    return <ListItem {..._listitem} />
                })
            }
            {/* <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} />
            <ListItem title={"Help"} iconProps={{icon:"help"}} /> */}
        </Stack>
    )
}