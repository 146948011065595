import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

export default function LimitsPayouts(){
    const data = [
        {
            bet:"Straight Up",
            payout:"35:1",
            min:10,
            max:240000
        },
        {
            bet:"Split",
            payout:"17:1",
            min:10,
            max:240000
        },
        {
            bet:"Street",
            payout:"11:1",
            min:10,
            max:240000
        },
        {
            bet:"Corner",
            payout:"8:1",
            min:10,
            max:240000
        },
        {
            bet:"Line",
            payout:"5:1",
            min:10,
            max:240000
        },
        {
            bet:"Column",
            payout:"2:1",
            min:10,
            max:240000
        },
        {
            bet:"Dozen",
            payout:"2:1",
            min:10,
            max:240000
        },
        {
            bet:"Red/Black",
            payout:"1:1",
            min:10,
            max:240000
        },
        {
            bet:"Even/Odd",
            payout:"1:1",
            min:10,
            max:240000
        },
        {
            bet:"1-18/19-36",
            payout:"1:1",
            min:10,
            max:240000
        },
    ]

    return(
        <Box mt={2} mx={-3} width={"calc(100% + 48px)"} height={"100%"} sx={{overflow:"auto"}}>
            {/* <CustomScroller width={"100%"}> */}
                <TableContainer sx={{height:"100%",width:"100%"}}>
                    <Table stickyHeader aria-label="sticky table" sx={{minWidth:350}}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{pl:3}}>Bet</TableCell>
                                <TableCell sx={{textAlign:"center"}}>Payout</TableCell>
                                <TableCell sx={{textAlign:"center"}}>Min</TableCell>
                                <TableCell sx={{textAlign:"center"}}>Max</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.map((e)=>{
                                    return(
                                        <TableRow>
                                            <TableCell sx={{pl:3}}>{e.bet}</TableCell>
                                            <TableCell sx={{textAlign:"center"}}>{e.payout}</TableCell>
                                            <TableCell sx={{textAlign:"center"}}>{e.min}</TableCell>
                                            <TableCell sx={{textAlign:"center"}}>{e.max}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            {/* </CustomScroller> */}
        </Box>
    )
}