import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionContext } from "../contexts/actionContext";
import { SendGameMsg } from "../services/GameCommunication";

export default function Settings(){
    const {settings} = useContext(ActionContext);
    return(
        <Box pt={3}>
            <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} pl={"14px"} py={"10px"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography>Sound</Typography>
                    <Switch checked={settings?.music} onClick={()=>SendGameMsg({type:"CHANGE_MUSIC"})} />
                </Stack>
                <Stack direction={"row"} pl={"14px"} py={"10px"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography>SFX Sound</Typography>
                    <Switch checked={settings?.sfx} onClick={()=>SendGameMsg({type:"CHANGE_SFX"})}/>
                </Stack>
            </Stack>
        </Box>
    )
}

