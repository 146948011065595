import { Box } from "@mui/material";
import PortraitAnim from "../animations/splash_portrait.json";
import LandscapeAnim from "../animations/splash_landscape.json";
import React, { useEffect, useState } from "react";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

export default function SplashBackground({orientation}){
    const [animRef,setAnimRef] = useState();

    useEffect(()=>{
        if(animRef){
            animRef.play();
            setTimeout(()=>{
                animRef.pause();
            },1500)
        }
    },[animRef])

    return(
        <Box
        component={"img"}
            src={`uiAssets/images/${orientation?"bg_portrait.jpg":"bg_landscape.jpg"}`}
            sx={{
                position:"absolute",
                width:"100%",
                height:"100%",
                bgcolor:"#000"
            }}
        >
            {/* <Player
                lottieRef={(ref)=>setAnimRef(ref)}
                style={{height:"auto",width:orientation?`${window.innerHeight * 9/16}px`:"100vw"}}
                // style={{height:"auto",width:"auto"}}
                src={orientation?PortraitAnim:LandscapeAnim}
            >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player> */}
        </Box>
    )
}