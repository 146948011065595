import { Box, Divider, Drawer, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionContext } from "../contexts/actionContext";
import CustomIcon from "../mini-components/CustomIcon";
import List from "../mini-components/List";
import BetHistory from "./BetHistory";
import HelpContent from "./HelpContent";
import LimitsPayouts from "./LimitsPayouts";
import Settings from "./Settings";
import Statistics from "./Statistics";

export default function SideDrawer(){
    const {openSideDrawer,setOpenSideDrawer} = useContext(ActionContext);
    const handleClose=()=>{
        setOpenSideDrawer(false);
    }
    const {setOpenInSideDrawer} = useContext(ActionContext);

    const ListData = [
        {
            title:"Statistics", 
            iconProps:{icon:"stats",sx:{width:20,height:20}},
            onClick:()=>setOpenInSideDrawer({anchor:"left",title:"Statistics",content:<Statistics />})
        },
        {
            title:"Bet History", 
            iconProps:{icon:"bet_history",sx:{width:20,height:20}},
            onClick:()=>setOpenInSideDrawer({anchor:"left",title:"Bet History",content:<BetHistory />})
        },
        {
            title:"Limits & Payouts", 
            iconProps:{icon:"bet_history",sx:{width:20,height:20}},
            onClick:()=>setOpenInSideDrawer({anchor:"left",title:"Limits And Payouts",content:<LimitsPayouts />})
        },
        {
            title:"Full Screen", 
            iconProps:{icon:"fullscreen",sx:{width:20,height:20}},
        },
        
        {
            title:"Settings", 
            iconProps:{icon:"setting",sx:{width:20,height:20}},
            onClick:()=>setOpenInSideDrawer({anchor:"left",title:"Settings",content:<Settings />})
        },
        {
            title:"Help", 
            iconProps:{icon:"help",sx:{width:20,height:20}},
            onClick:()=>setOpenInSideDrawer({anchor:"left",title:"Help",content:<HelpContent />})
        },
        {
            title:"Exit To Lobby", 
            iconProps:{icon:"exit",sx:{width:20,height:20}  },
        },
    ]

    return(
        <Drawer
            anchor="left"
            open={openSideDrawer}
            onClose={handleClose}
        >
            <Box 
            sx={{
                width:{xs:"70vw"},
                height:"100vh",
                px:{xs:"30px"},
                py:{xs:"30px"}
            }}
            >
                
                <Stack direction={"column"} height={"100%"}>
                    <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                        <Box
                            sx={{
                                width:36,height:36,
                                borderRadius:1.25,
                                background: "linear-gradient(160.68deg, #FFFFFF -15.89%, rgba(255, 255, 255, 0) 49.43%),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
                            }}
                        >

                        </Box>
                        <Stack direction={"column"} alignItems={"start"} spacing={0.25} flex={1} >
                            <Typography variant="subtitle2" color={"text.primary2"}>Hello 👋</Typography>
                            <Typography variant="h4" color={"text.secondary"}>User2211</Typography>
                        </Stack>
                        <Box 
                            sx={{
                                width:28,
                                height:28,
                                bgcolor:"primary.main",
                                borderRadius:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                // position:"absolute",
                                // top:35,
                                // right:16
                            }}
                            onClick={handleClose}
                        >
                            <CustomIcon sx={{bgcolor:"#000",width:12,height:12}} icon={"close"} />
                        </Box>
                    </Stack>
                    <Divider sx={{my:2,mb:0}}/>
                    <List colGap={1} sx={{overflow:"auto",py:3}} data={ListData} />
                    <Divider sx={{my:2,mt:0}}/>
                </Stack>
            </Box>
        </Drawer>
    )
}