

export default function Tabs(theme) {
    return {
        MuiTabs: {
        styleOverrides: {
          root:{
            minHeight: 30
          },
          indicator:{
            backgroundColor:theme.palette.primary.main
          },
          scroller:{
            paddingLeft:16,
            paddingRight:16,
          },
          flexContainer:{
            width:"fit-content"
          },
          scrollButtons:{
            width:20,
            color:"#FFF",
            [theme.breakpoints.up('xs')]: {
              display:"flex"
            },
            "&.Mui-disabled":{
              opacity: 0.3
            }
          }
        }
      },
        MuiTab: {
        styleOverrides: {
          root:{
            minHeight: 30,
            minWidth: "auto",
            padding:"0px 0px",
            fontSize:14,
            fontWeight:500,
            marginRight:16,
            color:theme.palette.text.primary,
            "&:last-of-type":{
                marginRight:0,
            },
            "&.Mui-selected": {
                color: theme.palette.primary.main
            }
          }
        }
      }
    };
  }
  