


export default function Slider(theme){
    return{
        MuiSlider:{
            styleOverrides:{
                root: {
                    height:6
                  },
                  rail:{
                    opacity:1,
                    backgroundColor:theme.palette.primary.light
                  },
                  thumb:{
                    width:12,
                    height:12,
                    border:"1px solid",
                    borderColor:theme.palette.primary.main,
                    backgroundColor:"#FFF"
                  }
                
            }
        }
    }
}