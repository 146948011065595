import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomIcon from "./CustomIcon";

export default function ListItem({onClick=()=>{},iconProps={},title,titleProps={}}){
    return(
        <Box
            onClick={onClick}
            sx={{
                px:"14px",
                py:1.5
            }}
        >   
        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
            <CustomIcon {...iconProps} />
            <Typography {...titleProps}>{title}</Typography>
        </Stack>
    </Box>
    )
}