
import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { wheelNumbers } from "../constants/constant";
import { GameDataContext } from "../services/GameCommunication";

import CustomIcon from "./CustomIcon";

export default function WinNumber(){
    const {pauseTimer,gameState,winNumber,config} = useContext(GameDataContext);
    const [show,setShow] = useState(false);

    useEffect(()=>{
        setShow(pauseTimer<10 && pauseTimer>6 && gameState ==="RESULT" && winNumber !== null)
    },[pauseTimer,gameState,winNumber])

    const findLeftNumber = ()=>{
        if(winNumber === 20){
            return wheelNumbers[36];
        }
        return wheelNumbers[wheelNumbers.findIndex((number)=>number.value===winNumber)-1];
    }

    const findRightNumber = ()=>{
        if(winNumber === 1){
            return wheelNumbers[0];
        }
        return wheelNumbers[wheelNumbers.findIndex((number)=>number.value===winNumber)+1];
    }
    

    return(
            <>
        
            <Box 
                sx={{
                    position:"fixed",
                    width:"100vw",
                    zIndex:2,
                    display:show ?"block":"none",
                }}
            >
                
                    <Box
                            sx={{
                                top:95,
                                left:"50%",
                                transform:"translateX(-50%)",
                                width:config.orientation === "L"?"60vw":"100vw",
                                height:"125px",
                                position:"absolute",
                                background:"linear-gradient(89.99deg, rgba(0, 0, 0, 0) 0.02%, rgba(0, 0, 0, 0.6) 21.88%, rgba(0, 0, 0, 0.95) 48.96%, rgba(0, 0, 0, 0.6) 78.12%, rgba(0, 0, 0, 0) 99.99%)",
                                animation: "slideIn 250ms ease-in-out forwards,slideOut 250ms ease-in-out 2.5s forwards",
                            }}
                        >

                    </Box>
                <Box
                    sx={{
                        
                        position:"fixed",
                        top:130,
                        left:"50%",
                        transform:"translate(-50%,0)",
                        width:config.orientation === "L"?"20vw":"50vw",
                        zIndex:2,
                        opacity:0,
                        // bgcolor:"#000000",
                        maxWidth:294.75,
                        "&::before":{
                            content:"''",
                            display:"block",
                            paddingTop:"40%",
                        },
                        animation:"fadeIn 150ms ease-in-out 250ms forwards,fadeOut 150ms ease-in-out 2.35s forwards",
                    }}
                >

                
                    <Typography variant="body3" sx={{position:"absolute",top:-25,left:"50%",transform:"translate(-50%,0)",color:"#FFF",fontWeight:500,whiteSpace:"nowrap"}}>Winning Number</Typography>
                    <CustomIcon icon={"win_number_border"} sx={{
                        width:"100%",
                        height:"100%",
                        position:"absolute",
                        left:0,
                        top:0,
                    }} />

                    {/* Left Number */}
                    <Box
                        sx={{
                            width:"34%",
                            height:"100%",
                            position:"absolute",
                            left:0,
                            top:4,
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <CustomIcon icon={"win_number_left"} sx={{
                            width:"100%",
                            height:"100%",
                            position:"absolute",
                            left:0,
                            top:0,
                            bgcolor:findLeftNumber()?.winBgColor,
                        }} />

                        <Typography variant="winNumber" sx={{
                            transform:"rotate(-22deg)"
                        }}>{findLeftNumber()?.value}</Typography>
                    </Box>
                    
                    {/* Win Number */}
                    <Box
                        sx={{
                            width:"41%",
                            height:"76%",
                            position:"absolute",
                            left:"50%",
                            top:0,
                            transform:"translate(-50%,0)",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <CustomIcon icon={"win_number"} sx={{
                            width:"100%",
                            height:"100%",
                            position:"absolute",
                            left:0,
                            top:0,
                            bgcolor:wheelNumbers.find((number)=>number.value===winNumber)?.winBgColor
                        }} />
                        <Typography variant="winNumber" sx={{
                            transform:"rotate(0deg)",
                            color:"#FFF"
                        }}>{winNumber}</Typography>
                    </Box>

                    {/* Right Number */}
                    <Box
                        sx={{
                            width:"34%",
                            height:"100%",
                            position:"absolute",
                            right:0,
                            top:2,
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <CustomIcon icon={"win_number_right"} sx={{
                            width:"100%",
                            height:"100%",
                            position:"absolute",
                            left:0,
                            top:0,
                            bgcolor:findRightNumber()?.winBgColor
                        }} />
                        <Typography variant="winNumber" sx={{
                            transform:"rotate(22deg)"
                        }}>{findRightNumber()?.value}</Typography>
                    </Box>
                    
                </Box>
            </Box>
            </>
    )
}