import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GameDataContext } from "../services/GameCommunication";

export default function WinAmmount(){
    const {winData,totalBets,gameState,pauseTimer} = useContext(GameDataContext);
    const [winAmmount,setWinAmmount] = useState(0);

    useEffect(()=>{
        // if(totalBets>0 && winData && gameState ==="RESULT" && pauseTimer<8){
        //     setWinAmmount(winData.win_amount - totalBets);
        //     console.log(winData.win_amount - totalBets,"profit loss")
        // }
        // else{
        //     setWinAmmount(0);
        // }
    },[winData,totalBets,gameState,pauseTimer])

    return(
        <Box
            sx={{
                position:"fixed",
                bottom:60,
                left:20,
                zIndex:5,
                width:"100%",
                opacity:0,
                textAlign:"left",
                transform:"translateY(25px)",
                animation:winAmmount>0?"slideUp 250ms ease-in-out forwards,fadeIn 250ms ease-in-out forwards,fadeOut 250ms ease-out forwards 1500ms,slideOutUp 250ms ease-out forwards 1500ms":"none"
            }}
        >
            <Typography variant="body2" color={"#FFF"}>You Won ${winAmmount}</Typography>
        </Box>
    )
}