import { Box } from "@mui/material";
import React from "react";

export default function CustomScroller({children,width}){
    return(
        <Box
            sx={{
                flex:1,
                overflow:"auto",
                pr:1,
                // marginRight:"-16px",
                width:width ? width : "calc(100% + 16px)",
                height:"100%",
                "::-webkit-scrollbar": {
                    width: "8px",
                    height: "8px",
                },
                "::-webkit-scrollbar-track":{
                    background: "transparent",
                },
                "::-webkit-scrollbar-thumb":{
                    background: "rgba(255, 255, 255, 0.05);",
                    border: "1px solid rgba(255, 255, 255, 0.15)",
                    borderRadius:"12px"
                }

            }}
        >
            {children}
        </Box>
    )
}