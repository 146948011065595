// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ xs,sm, md, lg,xl }) {
  return {
    '@media (min-width:0px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
    '@media (min-width:1550px)': {
      fontSize: pxToRem(xl),
    },
  };
}

const FONT_PRIMARY = 'Inter';

const typography = {
  fontFamily: FONT_PRIMARY,
  lineHeight:1.2,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 26, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ xs:24,sm: 24, md: 28, lg: 28,xl:28 }),
  },
  h4: {
    fontWeight: 600,
    lineHeight: "19px",
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ xs:16,sm: 16, md: 22, lg: 22,xl:22 }),
  },
  h5: {
    fontWeight: 600,
    lineHeight: "18px",
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs:18,sm: 18, md: 18, lg: 18,xl:18 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 300,
    lineHeight: 1,
    ...responsiveFontSizes({ xs:12,sm: 12, md: 12, lg: 12,xl:12 }),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1,
    fontSize: pxToRem(12),
  },
  subtitle3: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(9),
  },
  body1: {
    lineHeight: 1,
    fontSize: pxToRem(18),
    fontWeight:500,
    ...responsiveFontSizes({ xs:16,sm: 16, md: 18, lg: 18,xl:18 }),
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs:12,sm: 14, md: 14, lg: 14,xl:14 }),
  },
  body3: {
    lineHeight: "17px",
    fontSize: pxToRem(14),
    fontWeight:700,
    ...responsiveFontSizes({ xs:14,sm: 14, md: 16, lg: 16,xl:16 }),
  },
  label:{
    color:"#B0B0B0",
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs:12,sm: 14, md: 14, lg: 14,xl:14 }),
  },
  caption: {
    // color:"#B0B0B0",
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  mainCardTitle:{
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ xs:16,sm: 28, md: 28, lg: 28,xl:28 }),
  },
  modalTitle: {
    fontWeight: 500,
    lineHeight: 1.5,
    ...responsiveFontSizes({ xs:18,sm: 28, md: 28, lg: 28,xl:28 }),
  },
  chipText:{
    fontWeight:400,
    lineHeight:1,
    ...responsiveFontSizes({ xs:10,sm: 10, md: 14, lg: 14,xl:14 }),
  },
  placeHolder:{
    ...responsiveFontSizes({ xs:14,sm: 14, md: 14, lg: 14,xl:14 }),
  },
  tabBtn:{
    fontWeight:400,
    ...responsiveFontSizes({ xs:12,sm: 14, md: 14, lg: 14,xl:14 }),
  },
  timer:{
    color:"#FFF",
    fontWeight:500,
    lineHeight:"30px",
    ...responsiveFontSizes({ xs:28,sm: 28, md: 28, lg: 28,xl:28 }),
  },
  chip:{
    color:"#FFF",
    fontWeight:600,
    lineHeight:1,
    // ...responsiveFontSizes({ xs:20,sm: 20, md: 20, lg: 20,xl:20 }),
  },
  winNumber:{
    color:"#FFFFFFBF",
    fontWeight:600,
    lineHeight:1,
    ...responsiveFontSizes({ xs:34 }),
  }
};

export default typography;
