

export default function Switch(theme){
    return{
        MuiSwitch:{
            styleOverrides:{
                root: {
                    height:24,
                    width:52,
                    padding:0,
                    borderRadius:100
                  },
                thumb:{
                    height:16,
                    width:16,
                },
                track:{
                    backgroundColor:"#DFE5EC",
                    opacity:1,
                    transition:"opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:before":{
                        content:'"OFF"',
                        position:"absolute",
                        fontSize:10,
                        left:24,
                        top:"50%",
                        transform:"translateY(-50%)",
                        color:"#000",
                    }
                }
            }
        },
        MuiButtonBase:{
            styleOverrides:{
                root: {
                    "&.MuiSwitch-switchBase":{
                        padding:4,
                        transition:"left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&.Mui-checked":{
                            transform:"translateX(27px)",
                            color:"#000",
                            "+.MuiSwitch-track":{
                                opacity:1,
                                "&:before":{
                                    content:'"ON"',
                                    position:"absolute",
                                    left:10,
                                }
                            }
                        }
                    }
                  }
            }
        }
    }
}