import { createContext, useContext, useEffect, useState } from "react"
import { ActionContext } from "../contexts/actionContext";
import { Button, Stack } from '@mui/material';


const GameDataContext = createContext();

const GameCommunication = ({children}) =>{
    const [gameHistory,setGameHistory] = useState();
    const [betHistory,setBetHistory] = useState();
    const [roundId,setRoundId] = useState("");
    const [config,setConfig] = useState({device:"",orientation:""});
    const [balance,setBalance] = useState(0);
    const [totalBets,setTotalBets] = useState(0);
    const [gameState,setGameState] = useState("BETTING");
    const [winNumber,setWinNumber] = useState(-1);
    const [winData,setWinData] = useState(null);
    const [timer,setTimer] = useState(0);
    const [pauseTimer,setPauseTimer] = useState(0);
    const [selectedChip,setSelectedChip] = useState({value:1,fontSize:14,img:"green",});
    const {setModalData,setSettings} = useContext(ActionContext);

    const showHideConnectionError = (data) =>{
      if(data){
        setModalData(null)
      }
      else{
        setModalData({
          title:"Waiting for connection",
          subtitle:"There seems to be a problem with your network connection.",
          closeBtn:false,
          closeFtn:()=>{}
        })
      }
    }

    const showErrors = (data) =>{
      if(data.status_code === 600 || data.isExpired){
        console.log(data.status_code);
        setModalData({
          title:"Session expired",
          subtitle:data.isExpired?"You have logged in to your account from another device":"Please return to the site to log in again.",
          disableBackdropClose:true,
          closeBtn:false,
          closeFtn:()=>{}
        })
      }
      else if(data.status_code === 604){
        setModalData({
          title:"Bet Limit Exceeded",
          subtitle:data.status_message,
          closeBtn:false,
          closeFtn:()=>{}
        });
        setTimeout(()=>{
          setModalData(null);
        },1500);
      }
      else if(data.status_code === 606){
        setModalData({
          title:"Low Balance",
          subtitle:"Please Contact your Operator.",
          disableBackdropClose:true,
          closeBtn:<Stack>
            <Button variant="contained" sx={{color:"#000",fontWeight:700,minWidth:120}} onClick={()=>setModalData(null)}>OK</Button>
          </Stack>,
          closeFtn:()=>{}
        });
        // setTimeout(()=>{
        //   setModalData(null);
        // },1000);
      }
    }

    const listeners = [
      {
        type:"GAME_STATE",
        action:(data)=>setGameState(data),
      },
      {
        type:"GAME_CONFIG",
        action:(data)=>setConfig(data),
      },
      {
        type:"GAME_STATS",
        action:(data)=>setGameHistory(data),
      },
      {
        type:"ROUND_DATA",
        action:(data)=>setRoundId(data.round_name),
      },
      {
        type:"BALANCE",
        action:(data)=>{
          console.log(data,"balance")
          setBalance(data)
        },
      },
      {
        type:"TOTAL_BETS",
        action:(data)=>setTotalBets(data),
      },
      {
        type:"BET_HISTORY",
        action:(data)=>setBetHistory(data),
      },
      {
        type:"timer",
        action:(data)=>setTimer(data?.timeRemaining),
      },
      {
        type:"PAUSE_TIMER",
        action:(data)=>setPauseTimer(data),
      },
      {
        type:"WIN_NUMBER",
        action:(data)=>setWinNumber(data),
      },
      {
        type:"USER-BET-RESULT",
        action:(data)=>setWinData(data),
      },
      {
        type:"NEW_ROUND_STARTED",
        action:(data)=>window.parent.postMessage({type:"GET_STATS"}),
      },
      {
        type:"CONNECTION",
        action:showHideConnectionError,
      },
      {
        type:"SETTINGS_CHANGED",
        action:setSettings,
      },
      {
        type:"GAME-ERROR",
        action:showErrors,
      },
    ];

    


    useEffect(()=>{
        window.addEventListener('message', (event) => {

          let listen = listeners.find((_listen)=>_listen.type === event.data.type);
          if(listen){
            console.log('Received message from game:', event.data.data,event.data.type);
            listen.action(event.data.data);
          }
        });
      // eslint-disable-next-line
      },[setModalData])
    return(
        <GameDataContext.Provider value={{gameHistory,timer,betHistory,selectedChip,setSelectedChip,balance,totalBets,config,gameState,winNumber,roundId,pauseTimer,winData}}>
            {
                children
            }
        </GameDataContext.Provider>
    )
}

const SendGameMsg = (msg) =>{
  window.parent.postMessage(msg,"*")
}

export {GameCommunication,GameDataContext,SendGameMsg};