

export default function Divider(theme){
    return{
        MuiDivider:{
            styleOverrides:{
                root: {
                    borderColor:"#FFFFFF40"
                  },
            }
        }
    }
}