import { Box, Divider, Drawer, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionContext } from "../contexts/actionContext";
import CustomIcon from "./CustomIcon";

export default function InsideModel({anchor="left"}){
    const {openInsideDrawer,setOpenInSideDrawer} = useContext(ActionContext);
    const handleClose=()=>{
        setOpenInSideDrawer(null);
    }
    return(
        <Drawer
            anchor={openInsideDrawer?.anchor || anchor}
            open={openInsideDrawer}
            onClose={handleClose}
            slotProps={{
                backdrop:{
                    sx:{
                        bgcolor:"transparent",
                        backdropFilter:"none"
                    }
                }
            }}
            // hideBackdrop={true}
        >
            <Box
                sx={{
                    width:"75vw",
                    height:"100vh",
                    px:3,
                    overflow:"hidden"
                }}
            >
                
                <Stack direction={"column"} height={"100%"}>
                
                    <Box 
                        sx={{
                            pb:"16px",
                            pt:"30px"
                        }}
                    >
                        <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                        <Box
                            sx={{
                                width:36,height:36,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:28,
                                    height:28,
                                    bgcolor:"primary.main",
                                    borderRadius:"100%",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    // position:"absolute",
                                    // top:35,
                                    // right:16
                                }}
                                onClick={handleClose}
                            >
                                <CustomIcon  sx={{bgcolor:"#000",width:12,height:12}}  icon={"arrow_left"} />
                            </Box>
                        </Box>
                        <Typography flex={1} color={"text.secondary"} textAlign={"left"} variant="h5">{openInsideDrawer?.title}</Typography>
                        {/* <Stack direction={"column"} alignItems={"start"} spacing={0.25} flex={1} >
                            <Typography variant="subtitle2" color={"text.primary2"}>Hello 👋</Typography>
                            <Typography variant="h4" color={"text.secondary"}>User2211</Typography>
                        </Stack> */}
                        
                    </Stack>
                        {/* <Typography color={"text.secondary"} variant="h5">{openInsideDrawer?.title}</Typography> */}
                    </Box>
                    <Divider />
                    {/* <Box
                        sx={{
                            flex:1,
                            // overflow:"auto",
                            // pr:1,
                            // marginRight:"-16px",
                            // "::-webkit-scrollbar": {
                            //     width: "8px",
                            // },
                            // "::-webkit-scrollbar-track":{
                            //     background: "transparent",
                            // },
                            // "::-webkit-scrollbar-thumb":{
                            //     background: "rgba(255, 255, 255, 0.05);",
                            //     border: "1px solid rgba(255, 255, 255, 0.15)",
                            //     borderRadius:"12px"
                            // }

                        }}
                    > */}
                        {openInsideDrawer?.content}
                    {/* </Box> */}
                    {/* <Divider sx={{mb:5}} /> */}
                </Stack>
            </Box>
        </Drawer>
    )
}

