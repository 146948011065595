

export default function Dialog(theme){
    return{
        MuiDialog:{
            styleOverrides:{
                paper: {
                    marginLeft:"24px",
                    marginRight:"24px",
                    boxShadow:"0px 8px 25px -5px rgb(0 0 0)",
                    [theme.breakpoints.up('xs')]: {
                      width:"calc(100% - 48px)",
                    },
                    [theme.breakpoints.up('md')]: {
                        width:"auto",
                      },
                  },
            },

        },
        MuiDrawer:{
            styleOverrides:{
                paper: {
                    boxShadow:"0px 8px 25px -5px rgb(0 0 0)",
                    
                  },
            },

        },
        MuiBackdrop:{
            styleOverrides:{
                root: {
                    background: "rgba(0, 0, 0, 0.2)",
                    backdropFilter: "blur(3px)",
                } 
            },

        },
    }
}