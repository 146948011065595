import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { GameDataContext } from "../services/GameCommunication";
import CustomIcon from "./CustomIcon";

export default function Timer(){
    const {timer} = useContext(GameDataContext);
    // const calculatePercentage = ()=> {
    //     return(100*timer)/30;
    // }
    
    return(
        <Box
            sx={{
                position:"absolute",
                top:10,
                right:10,
                px:3,
                py:1,
                borderRadius:"50px",
                width:"fit-content",
                backdropFilter:"blur(5px)",
            }}
        >
            <Stack direction={"row"}  alignItems={"center"} spacing={0.5} >
                <Box sx={{
                    minWidth:35,
                    textAlign:"center"
                }}>
                    <Typography sx={{}} variant="timer">{timer}</Typography>
                    {/* <Typography sx={{}} variant="timer">24</Typography> */}
                </Box>
                <CustomIcon 
                    sx={{
                        width:21,
                        height:21
                    }}
                icon={"hourglass"} />
            </Stack>
            <Box
                sx={{
                    position:"absolute",
                    width:"100%",
                    height:"100%",
                    borderRadius:"50px",
                    bgcolor:"rgba(0, 0, 0, 0.5)",
                    // backdropFilter:"blur(5px)",
                    backgroundClip:"padding-box",
                    left:0,
                    top:0,
                    zIndex:-1
                }}
            />
            
        </Box>
        // <Box
        //     sx={{
        //         position:"absolute",
        //         top:20,
        //         right:20,
        //         width:53,
        //         height:53,
        //         bgcolor:"#00000066",
        //         borderRadius:"100%"
        //         // bgcolor:"red",
        //         // padding:2,
        //     }}
        // >
        //     <CircularProgress value={100} variant={"determinate"} sx={{
        //         position:"absolute",
        //         top:0,
        //         left:0,
        //         height:"calc(100% - 0px) !important",
        //         width:"calc(100% - 0px) !important",
        //         color:"#00000099",
        //         borderRadius:"100%",
        //         boxShadow:"0px 0px 6px rgba(255, 182, 32, 0.3)",
        //         [`& .MuiCircularProgress-circle`]: {
        //             strokeLinecap: 'round',
        //             strokeWidth:5
        //         },
        //         [`& .MuiCircularProgress-svg`]: {
        //             overflow:"visible",
        //         },
        //     }} />
        //     <CircularProgress value={-calculatePercentage()} variant={"determinate"} sx={{
        //         height:"100% !important",
        //         width:"100% !important",
        //         transition:"transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        //         [`& .MuiCircularProgress-circle`]: {
        //             strokeLinecap: 'round',
        //             strokeWidth:5,
        //             // transition:"stroke-dashoffset 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        //             transition:timer>28?"stroke-dashoffset 0.01s linear 0ms":"stroke-dashoffset 1s linear 0ms",
        //         },
        //         [`& .MuiCircularProgress-svg`]: {
        //             overflow:"visible",
        //         },
        //     }} />
        //     <Typography sx={{
        //         position:"absolute",
        //         top:"50%",
        //         left:"50%",
        //         transform:"translate(-50%,-50%)"
        //     }} variant="timer">{timer}</Typography>
        // </Box>
    )
}