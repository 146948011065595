
export default function Table(theme){
    return{
        MuiTable:{
            styleOverrides:{
                root: {
                    border:"none"
                  },
                
            }
        },
        MuiTableContainer:{
          styleOverrides:{
            root: {
                overflow:"auto"
              },
        }
        },
        MuiTableHead: {
            styleOverrides: {
              root: {
                
                marginBottom:12,
              },
              
            },
        },
        MuiTableBody: {
            styleOverrides: {
              root: {
                // paddingTop:12
              },
            },
        },
        MuiTableCell: {
            styleOverrides: {
              root: {
                whiteSpace:"nowrap",
                borderBottom:"none",
                fontSize:16,
                fontWeight:500,
                padding:"4px 0px",
                backgroundColor:"transparent",
                [theme.breakpoints.up('lg')]: {
                },
                [theme.breakpoints.up('xs')]: {
                  fontSize:16,
                },
              },
              body:{
                borderBottom:"1px solid #FFFFFF33",
                "&:first-child":{
                    // padding:"24px 32px",
                }
              },
              head:{
                fontSize:14,
                padding:"8px 0px",
                fontWeight:500,
                lineHeight:"16.94px",
                background:theme.palette.background.neutral, 
              }
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
              root:{
                "&.Mui-selected":{
                    backgroundColor:theme.palette.primary.main,
                    color:"white",
                    '&:hover': {
                        backgroundColor: "#B8CAF2",
                    }
                },
                '&:hover': {
                    backgroundColor: "#B8CAF2",
                }
              },
              page: {
                // paddingTop:12,
                backgroundColor:theme.palette.primary.light,
                color:theme.palette.primary.main
              },
            },
        },
    }
}