import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ChipSelector from "../mini-components/ChipSelector";
import CustomIcon from "../mini-components/CustomIcon";
import IOSFullscreen from "../mini-components/IOSFullscreen";
import { GameDataContext } from "../services/GameCommunication";

export default function Footer(){
    const { balance ,config,totalBets} = useContext(GameDataContext);
    var isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const [fullscreen,setFullscreen] = useState(false);
    const [Iosfullscreen,setIosFullscreen] = useState(false);

    /* View in fullscreen */
    function openFullscreen() {
        var elem = document.documentElement;
        console.log("working")
        if (elem.requestFullscreen) {
        elem.requestFullscreen();
        setFullscreen(true);
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
            setFullscreen(true);
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
            setFullscreen(true);
        }
    }
  
    /* Close fullscreen */
    function closeFullscreen() {
        if (document.exitFullscreen) {
        document.exitFullscreen();
        setFullscreen(false);
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
            setFullscreen(false);
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
            setFullscreen(false);
        }
    }

    const toggleFullscreen = () =>{
        
        if(isIos){
            if(fullscreen) {}
            else setIosFullscreen(true);
        }
        else{
            //windows & android
            if(fullscreen) closeFullscreen();
            else openFullscreen();
        }
    }

    return(
        <>
        {
            Iosfullscreen && 
            <IOSFullscreen isIos={isIos} setFullscreen={setFullscreen} fullscreen={fullscreen} />
        }
            <Box
                sx={{
                    position:"fixed",
                    bottom:0,
                    left:0,
                    width:"100vw",
                    zIndex:config.orientation ==="L"?4:2,
                    background:config.orientation ==="L"?"none":"linear-gradient(0deg, rgba(0, 0, 0, 1) 2.76%, rgba(0, 0, 0, 0) 95.6%)",
                    px:"10px",
                    py:"0px",
                    pr:"5px",
                    pt:config.orientation ==="L"?"0px":"45px"
                }}
            >
                
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box
                        position={"relative"}
                        left={-10}
                        bottom={0}
                        width={"35%"}
                        maxWidth={"150px"}
                    >
                        <Box
                            component={"img"}
                            src={"uiAssets/images/balance.svg"}
                            
                        >
                        </Box>
                        <Typography variant="subtitle3" sx={{color:"#000",position:"absolute",left:"30%",top:"8%",fontStyle:"italic"}}>Balance</Typography>
                        <Typography variant="subtitle2" sx={{fontWeight:700,color:"#FFF",position:"absolute",left:"15%",top:"45%",fontStyle:"italic"}}>USD {balance}</Typography>
                    </Box>
                    {/* <Box
                        sx={{
                            position:"relative",
                            width:"fit-content",
                            px:"20px",
                            backdropFilter:"blur(5px)",
                            py:1
                        }}
                    >
                        <Stack direction={"column"} alignItems={"start"}>
                            <Typography variant="subtitle1" sx={{fontWeight:500,color:"text.secondary"}}>Balance:</Typography>
                            <Typography variant="body3" sx={{fontWeight:700,color:"primary.main"}}>USD {balance}</Typography>
                        </Stack>
                        <Box
                        sx={{
                            position:"absolute",
                            width:"100%",
                            height:"100%",
                            borderRadius:"50px",
                            bgcolor:"rgba(0, 0, 0, 0.5)",
                            backdropFilter:"blur(5px)",
                            backgroundClip:"padding-box",
                            left:0,
                            top:0,
                            zIndex:-1
                        }}
                    />
                    </Box> */}
                    {
                        totalBets ? 
                        <Box
                            sx={{
                                position:"absolute",
                                left:"50%",
                                transform:"translateX(-50%)",
                                bottom:config.orientation ==="L"?10:15
                            }}
                        >
                            <Typography variant="subtitle2" sx={{color:"#FFF"}}>Total Bets: USD {totalBets}</Typography>
                        </Box>
                        :<></>
                    }
                    <CustomIcon onClick={toggleFullscreen} sx={{width:22,height:22,mr:"10px"}} icon={"fullscreen-2"} />
                </Stack>
            </Box>
        </>
    )
}