import './App.css';
import Header from './components/Header';
import ThemeProvider from './theme';
import SideDrawer from './components/SideDrawer';
import { ActionContextProvider } from './contexts/actionContext';
import InsideModel from './mini-components/InsideModal';
import { GameCommunication } from './services/GameCommunication';
import PopupModal from './mini-components/PopupComponent';
import Footer from './components/Footer';
import ChipSelector from './mini-components/ChipSelector';
import RecentNumbers from './components/RecentNumbers';
import WinNumber from './mini-components/WinNumber';
import WinAmmount from './mini-components/WinAmount';
import Splashscreen from './components/Splashscreen';
import LowerThirds from './components/LowerThirds';
import LoginPage from './pages/login';
import { useEffect, useState } from 'react';

function App() {
  const [isLoggedIn,setIsLoggedIn] = useState(true);

  const checkLoggedIn = () =>{
    var token = sessionStorage.getItem('token');
    if(!token) setIsLoggedIn(false);
  }

  useEffect(()=>{
    checkLoggedIn();
  },[])

  return (
    
    <div className="App">
      <ThemeProvider>
        {
          !isLoggedIn ?
          <LoginPage checkLoggedIn={checkLoggedIn} />
          :
          <ActionContextProvider>
            <GameCommunication>
              <Splashscreen />
              <LowerThirds />
              <WinNumber />
              <WinAmmount />
              <PopupModal />
              <Header />
              <SideDrawer />
              <InsideModel />
              <RecentNumbers />
              <ChipSelector />
              <Footer />
            </GameCommunication>
          </ActionContextProvider>
        }
      </ThemeProvider>
    </div>
  );
}

export default App;
