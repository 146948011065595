import { createContext, useEffect, useState } from "react";

import { useIdleTimer } from 'react-idle-timer'


export const ActionContext =  createContext();

export const ActionContextProvider = ({children}) =>{

    const [openSideDrawer,setOpenSideDrawer] = useState(false);
    const [openInsideDrawer,setOpenInSideDrawer] = useState(false);
    const [modalData,setModalData] = useState();
    const [inactive,setInactive] = useState(false);
    const [settings,setSettings] = useState();

    const onIdle = () => {
        // setState('Idle')
        if(!modalData){
            setInactive(true);
            setModalData({
                title:"User Inactive",
                subtitle:"You seem to be idle at the moment",
                closeBtn:false,
                closeFtn:()=>{}
              });
            console.log("idle")
        }
      }
    
      const onActive = () => {
        // setState('Active')
        if(inactive && modalData){
            setModalData(null);
            console.log("active")
        }
      }
    
      const onAction = () => {
        // setCount(count + 1)
        // console.log("action")
      }
    
      useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * 60 * 3,
        throttle: 0
      })

    return(
        <ActionContext.Provider value={{openSideDrawer,setOpenSideDrawer,openInsideDrawer,setOpenInSideDrawer,modalData,setModalData,settings,setSettings}}>
            {
                children
            }
        </ActionContext.Provider>
    )
}