import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionContext } from "../contexts/actionContext";
import CustomIcon from "./CustomIcon";

export default function PopupModal(){
    const {modalData,setModalData} = useContext(ActionContext);
    const handleClose=()=>{
        setModalData(null);
    }
    return(
        <Dialog
            open={Boolean(modalData)}
            onClose={modalData?.disableBackdropClose?()=>{}: handleClose}
            PaperProps={{
                sx:{
                    maxWidth:334
                }
            }}
        >
            <DialogContent>
                {/* {
                    modalData?.closeBtn &&
                    <Box 
                        sx={{
                            width:28,
                            height:28,
                            bgcolor:"primary.main",
                            borderRadius:"100%",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            position:"absolute",
                            top:16,
                            right:16
                        }}
                        onClick={handleClose}
                    >
                        <CustomIcon sx={{bgcolor:"#000",width:12,height:12}} icon={"close"} />
                    </Box>
                } */}
                <Stack sx={{
                    px:1,
                    py:"10px"
                }} 
                direction={"column"} spacing={2} alignItems={"center"}>
                    <CustomIcon sx={{width:126,height:126,bgcolor:"primary.main"}} icon={"safety"} />
                    <Typography color={"#FFF"} variant="h5">{modalData?.title}</Typography>
                    <Typography fontWeight={400} color={"#FFF"} variant="body3">{modalData?.subtitle}</Typography>
                    {modalData?.closeBtn}
                </Stack>
            </DialogContent>
        </Dialog>
    )
}