import { Box } from "@mui/material";
import React from "react";

export default function CustomIcon({sx,icon,onClick=()=>{}}){
    return(
        // <Box
        //         component={"img"}
        //         src={`/uiAssets/icons/${icon}.svg`}
        //         sx={{...sx}}
        //     />
        <Box
        onClick={onClick}
        component="span"
        sx={{
            width: 16,
            height: 16,
            bgcolor:"#FFF",
            display: 'inline-block',
            mask: `url(/uiAssets/icons/${icon}.svg) no-repeat center / contain`,
            WebkitMask: `url(/uiAssets/icons/${icon}.svg) no-repeat center / contain`,
            ...sx
        }}
        />
    )
}