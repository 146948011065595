import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GameDataContext, SendGameMsg } from "../services/GameCommunication";
import CustomIcon from "./CustomIcon";

export default function ChipSelector(){
    const [open,setOpen] = useState(false);

    const {selectedChip,setSelectedChip,config,gameState,winNumber,pauseTimer} = useContext(GameDataContext);

    const handleOpen = (id) =>{
        let style={};
        let openStates = [
           'translate3d(-7.35em, 1.4927em, 0px)',
           'translate3d(-6.5525em, -3.6489em, 0px)',
           'translate3d(-2.6015em, -7.0343em, 0px)',
           'translate3d(2.6015em, -7.0343em, 0px)',
           'translate3d(6.5525em, -3.6489em, 0px)',
           'translate3d(7.35em, 1.4927em, 0px)',
        ]
        if(config.orientation === "L"){
            openStates = [
                'translate3d(-0.4927em,-5.35em, 0px)',
                'translate3d(-5.6489em,-4.5525em, 0px)',
                'translate3d(-9.0343em,-0.6015em, 0px)',
                'translate3d(-9.0343em,4.6015em, 0px)',
                'translate3d(-5.6489em,8.5525em, 0px)',
                'translate3d(-0.4927em,9.35em, 0px)',
            ]
        }
        if(open) style.transform = openStates[id];
        return style;
    }

    const chips = [
        {
            value:1,
            fontSize:"1em",
            img:"green",
        },
        {
            value:5,
            fontSize:"1em",
            img:"red",
        },
        {
            value:25,
            fontSize:"1em",
            img:"blue",
        },
        {
            value:50,
            fontSize:"1em",
            img:"purple",
        },
        {
            value:100,
            fontSize:"1em",
            img:"orange",
        },
        {
            value:500,
            fontSize:"0.85em",
            img:"black",
        },
    ]

    const Chip = ({value=1,fontSize="1.5em",chip="chip"})=>{
        return(
            <Box
                sx={{
                    borderRadius: '50%',
                    // fontSize: '4em',
                    position: 'relative',
                    height: '100%',
                    transform: 'translate(0)',
                    width: '100%',
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
            >
                 {/* <Typography variant="chip"
                    sx={{
                        // position:"absolute",
                        // left:"50%",
                        // top:"50%",
                        // transform:"translate(-50%,-50%)",
                        fontSize:fontSize
                    }}
                >{value}</Typography> */}
                <Box sx={{width:"100%",height:"100%",position:"absolute",
                top:0,
                left:0,
                zIndex:-1
                        }} component={"img"} src={`/uiAssets/images/${chip}.png`} />
               
            </Box>
        )
    }
    
    const SelectedChip = ({stack=0}) =>{
        const stackNo = [0,0.2,0.4];
        return(
            <Box
                    sx={{
                        height: '4.4em',
                        position: 'absolute',
                        top: '0',
                        width: '4.4em',
                        transform: `translate${config.orientation === "L"?"X":"Y"}(${stackNo[stack]}em)`
                    }}
                >
                    <Chip chip={selectedChip.img} value={selectedChip.value} />
                </Box>
        )
    }

    useEffect(()=>{
        if(gameState !== "BETTING"){
            setOpen(false);
        }
    },[gameState])

    return(
        <>
        <Box
                sx={{
                    position:"fixed",
                    // bottom:config.orientation === "L"?20:60,
                    bottom:winNumber == -1 && gameState == "WAITFORROUND"?"70%":config.orientation === "L"?20:60,
                    left:0,
                    width:"100%",
                    background:"rgba(0, 0, 0, 0.4)",
                    boxShadow:"0px -2px 4px rgba(0, 0, 0, 0.25)",
                    backdropFilter:"blur(75px)",
                    py:1.5,
                    transition:"all 0.3s ease-in-out",
                    opacity:gameState == "RESULT" || (winNumber == -1 && gameState == "WAITFORROUND")?1:0,
                    zIndex:gameState == "RESULT"  || (winNumber == -1 && gameState == "WAITFORROUND")?1:-1
                    // opacity:1,
                    // zIndex:1
                }}
            >
                <Typography color={"text.secondary"} mb={1}>
                    Please wait for the next round.
                </Typography>
                {
                    (winNumber == -1 && gameState == "WAITFORROUND")?
                    <Typography color={"text.secondary"}>
                        {pauseTimer} s
                    </Typography>
                    :
                    gameState == "RESULT" && pauseTimer <6 && 
                    <Typography color={"text.secondary"}>
                        {pauseTimer} s
                    </Typography>

                }
            </Box>
            <Box
                sx={{
                    position:"fixed",
                    bottom:20,
                    right:100,
                    zIndex:6,
                    display:gameState === "BETTING" && config.device === "Desktop"?"block":"none"
                }}
            >
                <Stack direction={"row"} spacing={3} alignItems={"end"}>
                    <ButtonComponent sx={{width:60,height:60,}} onClick={()=>SendGameMsg({type:"ACT_TWOX"})}>
                        <Typography color={"text.secondary"}>
                            2x
                        </Typography>
                    </ButtonComponent>
                    <ButtonComponent sx={{width:60,height:60,}} onClick={()=>SendGameMsg({type:"ACT_UNOD"})}>
                        <CustomIcon icon={"undo"} sx={{width:"35px",height:"35px",mt:0.7}} />
                    </ButtonComponent>
                </Stack>
            </Box>
            <Stack
            direction={config.orientation==="L"?"column-reverse":"row"}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={3}
            columnGap={3}
                sx={{
                    display:config.device=== "Mobile"?"flex":"none",
                    fontSize:"14px",
                    position:"fixed",
                    bottom:config.orientation === "L"?0:gameState !== "BETTING"?-80:40,
                    right:config.orientation === "L"?gameState !== "BETTING"?-80:10:0,
                    width:config.orientation === "L"?"auto":"100%",
                    height:config.orientation === "L"?"100%":"auto",
                    zIndex:config.orientation === "L"?gameState !== "BETTING"?2:3:3,
                    transition:"all 0.3s ease-in-out",
                    "@media only screen and (max-height: 650px)":{
                        fontSize:"10px",
                        bottom:config.orientation === "L"?0:gameState !== "BETTING"?-80:20
                    }
                }}
            >
                
                {/* overlay */}
                <Box
                onClick={()=>setOpen(false)}
                        sx={{
                            background: 'rgba(0, 0, 0, 0.01)',
                            bottom: -40,
                            display: 'block',
                            width:"100vw",
                            height:"100vh",
                            left: '0',
                            top:0,
                            opacity: open?'0.9':'0',
                            pointerEvents: open?'auto':'none',
                            position: 'fixed',
                            // right: '0',
                            // top: '0',
                            transform: 'translateZ(0)',
                            transitionDuration: open?'0.45s':'0.3s',
                            transitionProperty: 'opacity',
                            zIndex:1,
                            "&::before":{
                                bottom: '0',
                                content: '""',
                                display: 'block',
                                left: '0',
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                background: `linear-gradient(${config.orientation ==="L"?-90:0}deg, #000, rgba(35, 31, 32, 0.7), transparent)`,
                            }
                        }}
                    >

                    </Box>

                <ButtonComponent onClick={()=>SendGameMsg({type:"ACT_TWOX"})}>
                    <Typography color={"text.secondary"}>
                        2x
                    </Typography>
                </ButtonComponent>
                <Box
                    sx={{
                        display: 'inline-block',
                        fontSize: '1em',
                        height: '4.4em',
                        lineHeight: '1',
                        position: 'relative',
                        verticalAlign: 'middle',
                        width: '4.4em',
                        zIndex: 2
                    }}
                >

                    

                    {/* Other Chips */}
                    <Box
                        component={"ul"}
                        sx={{
                            listStyle: 'none',
                            margin: '0',
                            padding: '0',
                            pointerEvents: 'none',
                            position: 'relative',
                            top: config.orientation === "L"?'-7.5em':'-6.85em',
                            left: '-5.3em',
                            width: '15em',
                            height: '15em'
                        }}
                    >
                        {
                            chips.map((e,i)=>{
                                return(
                                    <Box
                                        onClick={()=>{
                                            setOpen(false);
                                            setSelectedChip(e);
                                            SendGameMsg({type:"SELECT_CHIP",data:e.value.toString()})
                                        }}
                                        component={"li"}
                                        sx={{
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            display: 'block',
                                            pointerEvents: 'auto',
                                            position: 'absolute',
                                            touchAction: 'manipulation',
                                            transitionDuration: '0.3s',
                                            boxShadow: '0 0 0.2em 0 rgba(0, 0, 0, 0.5)',
                                            transitionProperty: 'transform',
                                            width: '3.6em',
                                            height: '3.6em',
                                            top: '5.7em',
                                            left: '5.7em',
                                            transform: 'translateY(2em) rotate(180deg)',
                                            ...handleOpen(i)
                                        }}
                                    >
                                        <Chip value={e.value} fontSize={e.fontSize} chip={e.img} />
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    {/* chip Stack */}
                    <Box
                        sx={{
                            cursor: 'pointer',
                            height: '4.4em',
                            left: '0',
                            position: 'absolute',
                            top: '0',
                            touchAction: 'manipulation',
                            transition: 'transform 0.3s',
                            width: '4.4em',
                            transform: open?config.orientation === "L"?'translateX(-2.55em)':'translateY(-2.55em)':"none",
                            boxShadow:"0px 5px 5px rgba(0, 0, 0, 0.4)",
                            filter:"drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.4))",
                            borderRadius:"100%"
                        }}
                    >

                        {/* chipStack-1 */}
                        {/* <SelectedChip stack={2} /> */}
                        {/* chipStack-2 */}
                        {/* <SelectedChip stack={1} /> */}

                        {/* Highlight ring */}
                        <Box
                            sx={{
                                backgroundColor: '#000',
                                border: '0.2em solid #ffe600',
                                borderRadius: '50%',
                                bottom: '-0.4em',
                                boxShadow: '0 0 0.1em 0.1em rgba(255, 230, 0, 0.5)',
                                left: '-0.4em',
                                opacity: open?'1':'0',
                                position: 'absolute',
                                right: '-0.4em',
                                top: '-0.4em',
                                transform: `translate(${config.orientation === "L"?-0.5:0.5}%,${config.orientation === "L"?-0.5:-1.5}%)`,
                                transition: 'opacity 0.3s linear',
                                transitionDelay: open?'0.1s':'0s'
                            }}
                        >

                        </Box>

                        {/* SelectedChip */}
                        <Box onClick={()=>setOpen(!open)}>
                            <SelectedChip />
                        </Box>
                    </Box>
                </Box>
                <ButtonComponent onClick={()=>SendGameMsg({type:"ACT_UNOD"})}>
                <CustomIcon icon={"undo"} sx={{width:"25px",height:"25px",mt:0.7}} />
                </ButtonComponent>
            </Stack>
        </>
    )
}


const ButtonComponent = ({children,onClick=()=>{},sx={}}) =>{
    return(
        <Box
            onClick={onClick}
            sx={{
                width:35,height:35,
                borderRadius:"100%",
                boxShadow:"0px 5px 5px rgba(0, 0, 0, 0.4)",
                border:"1.3875px solid rgba(255, 255, 255, 0.2)",
                bgcolor:"#1E5F51",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                ...sx
            }}
        >
            {children}
        </Box>
    )
}
