import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

export default function IOSFullscreen({isIos,fullscreen,setFullscreen}){
    
    useEffect(()=>{
        window.onscroll = function (){
            // alert(window.innerHeight+","+window.screen.height);
            console.log(window.screen.width,"screen width",window.innerHeight,"window height");
            if(window.innerHeight == window.screen.height || (
            isIos && (window.screen.height - window.innerHeight)<100)){
            //   alert("FullScreen")
                setFullscreen(true);
            }
            else{
                console.log("Not Fullscreen")
                // setFullscreen(false);
            }
          };
    })

    return(
        <Box
            sx={{
                height:"200vh",
            }}
        >
            <Box 
                sx={{
                    position:"fixed",
                    width:"100vw",
                    height:"100vh",
                    bgcolor:"#000",
                    zIndex:isIos && !fullscreen?99:-1,
                    left:0,
                    top:0
                }}
            >
                <Box
                    sx={{
                        position:"absolute",
                        left:"50%",
                        top:"50%",
                        height:"25vh",
                        transform:"translate(-50%,-50%)"
                    }}
                >
                    <Box
                        component={"img"}
                        src={"uiAssets/images/line.png"}
                        sx={{
                            height:"25vh",
                        }}
                    >
                    </Box>
                    <Box
                        component={"img"}
                        src={"uiAssets/images/hand.png"}
                        sx={{
                            position:"absolute",
                            height:"15vh",
                            left:"40%",
                            top:"100%",
                            maxWidth:"none",
                            // transform:"translate(-50%,-50%)",
                            animation:"swipe-up 1s ease-in-out",
                            animationDirection:"alternate-reverse",
                            animationIterationCount:"infinite",
                        }}
                    >
                    </Box>
                </Box>
                <Typography 
                sx={{
                    position:"absolute",
                    left:"50%",
                    top:"75%",
                    transform:"translate(-50%,-50%)",
                    color:"primary.main"
                }}
                >Swipe up to fullscreen</Typography>
            </Box>
        </Box>
    )
}