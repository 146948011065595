

export default function Checkbox(theme){
    return{
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    paddingTop:0
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                root:{
                    alignItems:"start"
                }
            }
        }
    }
}