// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize:14,
          fontWeight:300,
          height:"fit-content",
          padding:"12px 24px",
          lineHeight:1.2,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
          [theme.breakpoints.up('xs')]: {
            fontSize:12,
            // padding:"8px 16px",
          },
          [theme.breakpoints.up('md')]: {
            fontSize:14,
            padding:"12px 24px",
          },
        },
        sizeLarge: {
          height: 48
        },
        contained:{
          
        },
        text:{
          padding:0,
          '&:hover': {
            backgroundColor: "transparent",
          }
        },
        outlined:{
          backgroundColor: "transparent",
          '&:hover': {
            backgroundColor: "#B8CAF2",
          }
        },
        containedInherit: {
          color: theme.palette.grey[800],
          
          // boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          // boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          padding:"16px 24px",
          [theme.breakpoints.up('md')]: {
            fontSize:14,
            padding:"19px 24px",
          },
          // boxShadow: theme.customShadows.secondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    },
    PrivatePickersYear:{
      styleOverrides: {
        button:{
          color:"#252525",
          "&.Mui-disabled":{
            color:"#B0B0B0"
          }
        }
      }
    },
    PrivatePickersMonth:{
      styleOverrides: {
        root:{
          color:"#252525",
          "&:disabled":{
            color:"#B0B0B0"
          }
        }
      }
    }
  };
}
