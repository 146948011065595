import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import landscapeAnim from "../animations/landscape1.json";
import potraitAnim from "../animations/portrait1.json";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useState } from "react";

export default function SplashForeground({orientation,percentage}){
    const [gameTitlePlayer,setGameTitlePlayer] = useState();
    const [load,setLoad] = useState(false);
    

    useEffect(()=>{
        setTimeout(()=>{
            setLoad(true);
            if(gameTitlePlayer){
                    gameTitlePlayer.play();
                setTimeout(()=>{
                    gameTitlePlayer.pause()
                },2000)
            }
        },1500)
    },[gameTitlePlayer])



    return(
        <Box
            sx={{
                position:"absolute",
                width:"100%",
                height:"100%",
                left:0,
                top:0,
                opacity:load?1:0,
                transition:"all 0.2s ease-in-out",
            }}
        >

            <Box
                sx={{
                    position:"absolute",
                    left:orientation?"0":"0",
                    top:orientation?-50:-30,
                    height:"100%",
                    // transform:`translate(${orientation?"0":"-50%"},-50%)`,
                }}
            >
                <Player
                lottieRef={(ref)=>setGameTitlePlayer(ref)}
                style={{height:"100vh",width:"100vw"}}
                // autoplay
                src={orientation?potraitAnim:landscapeAnim}
                >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
            </Box>

            {/* Loader */}
            <Stack
                spacing={1.5}
                direction={"row"}
                alignItems={"center"}
                sx={{
                    position:"absolute",
                    bottom:"22%",
                    left:"50%",
                    transform:"translateX(-50%)",
                    width:"75%",
                    maxWidth:250
                }}
            >
                <Box
                    sx={{
                        width:"100%",
                        height:6,
                        bgcolor:"#eee",
                        borderRadius:"100px"
                    }}
                >
                    <Box sx={{
                        overflow:"hidden",
                        borderRadius:"100px",
                        height:6,
                        width:`${percentage}%`,
                        bgcolor:"primary.main",
                        boxShadow:"0 10px 40px -10px #fff",
                        transition:"all 0.2s linear"
                    }}></Box>
                    
                </Box>
                <Typography
                    sx={{
                        color:"primary.main"
                    }}
                >{percentage}%</Typography>
            </Stack>

            <Typography variant="subtitle3" sx={{color:"#FFF",position:"absolute",left:"50%",bottom:orientation?"60px":40,fontStyle:"italic",transform:"translateX(-50%)"}} >Powered By</Typography>
            <Typography variant="body3" sx={{fontWeight:600,position:"absolute",left:"50%",bottom:orientation?"40px":20,fontStyle:"italic",transform:"translateX(-50%)"}} color={"text.secondary"}>Gamesanct</Typography>
        </Box>
    )
}