export const wheelNumbers = [
    { value: 20, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 14, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 31, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 9, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 22, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 18, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 29, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 7, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 28, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 12, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 35, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 3, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 26, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 0, color: "#1FB02A",winBgColor:"#00D22E" },
    { value: 32, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 15, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 19, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 4, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 21, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 2, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 25, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 17, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 34, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 6, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 27, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 13, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 36, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 11, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 30, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 8, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 23, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 10, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 5, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 24, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 16, color: "#FF2727",winBgColor:"#FF0000AA" },
    { value: 33, color: "#FFFFFF",winBgColor:"#000000AA" },
    { value: 1, color: "#FF2727",winBgColor:"#FF0000AA" }
  ]