import { Box } from "@mui/material";
import useSound from 'use-sound';
import PlaceLand from "../animations/place_your_bets_landscape.json";
import PlacePort from "../animations/place_your_bets_portrait.json";
import WonPort from "../animations/you_won_portrait.json";
import WonLand from "../animations/you_won_landscape.json";
import React, { useContext, useEffect, useState } from "react";
import { GameDataContext } from "../services/GameCommunication";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import placeSound from "../sounds/place-your-bets.mp3";
import bgMusic from "../sounds/bg_Music.mp3";
import { ActionContext } from "../contexts/actionContext";

export default function LowerThirds(){
    const [playerController,setPlayerController] = useState();
    // const [isPlaying,setPlaying] = useState();
    // WonPort.layers[0].t.d.k[0].s.t = "20";
    const [playerSrc,setPlayerSrc] = useState();
    const [lowerThird,setLowerThird] = useState(false);

    const [placeBet,setPlaceBet] = useState(false);
    const [youWon,setYouWon] = useState(false);
    const [bgAudio,setBGAudio] = useState(new Audio(bgMusic));
    const [play] = useSound(placeSound);


    

    

    const {roundId,gameState,config,winData,totalBets,pauseTimer} = useContext(GameDataContext);
    const {settings} = useContext(ActionContext);

    useEffect(()=>{
        bgAudio.loop = true;
        bgAudio.volume = 0.05;
        // bgAudio.addEventListener("canplaythrough", () => {
        //     bgAudio.play().catch(e => {
        //        window.addEventListener('click', () => {
        //           bgAudio.play()
        //        })
        //     })
        //  });
        if(settings?.music){
            bgAudio.play()
        }
        else{
            bgAudio.pause();
        }
    },[settings])

    useEffect(()=>{
        if(roundId && gameState === "BETTING"){
            PlaceLand.layers[0].t.d.k[0].s.t = "Place your Bets".toUpperCase();
            PlacePort.layers[0].t.d.k[0].s.t = "Place your Bets".toUpperCase();
            setPlayerSrc(config.orientation === "L"?PlaceLand:PlacePort);
            setPlaceBet(true);
        }
    },[roundId,gameState]);

    useEffect(()=>{
        if(totalBets>0 && winData && gameState ==="RESULT" && pauseTimer===6){
            let winAmount = winData.win_amount - totalBets
            if(winAmount > 0){
                WonPort.layers[0].t.d.k[0].s.t = winData.win_bets[0].currency_name + " " +(winAmount);
                WonLand.layers[0].t.d.k[0].s.t = winData.win_bets[0].currency_name + " " +(winAmount);
                setPlayerSrc(config.orientation === "L"?WonLand:WonPort);
                setYouWon(true);
            }
            console.log(winAmount,"profit loss")
        }
        // else{
        //     setYouWon(0);
        // }
    },[winData,totalBets,gameState,pauseTimer])

    useEffect(()=>{
        if(playerSrc && playerController){
            if(placeBet){
                setLowerThird(true);
                setTimeout(()=>{
                    playerController.play();
                    play();
                },100)
                setTimeout(()=>{
                    setLowerThird(false)
                    setPlaceBet(false);
                },2100) 
            }
            if(youWon){
                setLowerThird(true);
                setTimeout(()=>{
                    playerController.play();
                },100)
                setTimeout(()=>{
                    setLowerThird(false)
                    setYouWon(false);
                },3100) 
            }
        }
    },[playerSrc,placeBet,playerController,youWon])

    useEffect(()=>{
        // setTimeout(()=>{
        //     setInterval(()=>{
        //         WonPort.layers[0].t.d.k[0].s.t = "20"
        //         setPlayerSrc(playerController);
        //     },1000)
        // },3500)
    },[])

    return(
        <Box>
            <Box
                sx={{
                    position:"fixed",
                    left:0,
                    zIndex:lowerThird?9:0,
                }}
            >

                {/* place your bets player */}
                <Player
                    lottieRef={(ref)=>setPlayerController(ref)}
                    style={{width:"100vw"}}
                    src={playerSrc}
                    >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
                
                {/* You won */}
                {/* <Player
                    key={"35"}
                    lottieRef={(ref)=>setYouWonController(ref)}
                    autoplay
                    loop
                    src={WonPort}
                    >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player> */}
            </Box>
        </Box>
    )
}